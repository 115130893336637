import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

function Services() {
    useEffect (() => {
        document.title = "Engineering Consortium | Services";
    }, 
    []);
    return (
        <>
        <div className="services pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">Services</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/services/architecture-methodology/">
                            <div className="img-cont"><img src="/services/Architecture-methodology-Service.jpg" alt="Architecture Methodology" className="img-fluid" /></div>
                            <h4>Architecture Methodology</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/services/structural-and-civil-engineering/">
                            <div className="img-cont"><img src="/services/Structural.jpg" alt="Structural & Civil Engineering" className="img-fluid" /></div>
                            <h4>Structural & Civil Engineering</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/services/mechanical-engineering/">
                            <div className="img-cont"><img src="/services/mechanical-engineering.jpg" alt="Mechanical Engineering" className="img-fluid" /></div>
                            <h4>Mechanical Engineering</h4> 
                        </NavLink>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/services/electrical-engineering/">
                            <div className="img-cont"><img src="/services/electrical-engineering.jpg" alt="Electrical Engineering" className="img-fluid" /></div>
                            <h4>Electrical Engineering</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/services/green-building-compliance/">
                            <div className="img-cont"><img src="/services/green-building-compliance-service.jpg" alt="Green Building Compliance" className="img-fluid" /></div>
                            <h4>Green Building Compliance</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/services/fire-and-security/">
                            <div className="img-cont"><img src="/services/fire-and-security-service.jpg" alt="Fire & Security" className="img-fluid" /></div>
                            <h4>Fire & Security</h4>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Services;