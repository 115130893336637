import Carousel from 'react-bootstrap/Carousel';

function MainSlider() {
  return (
        <div className='mainslider'>
                <Carousel fade={true} interval={null} controls={true} indicators={true}>
                    <Carousel.Item>
                        <img className="d-block w-100" src="banner/header.jpg" alt="First slide" />
                        <Carousel.Caption className='text-start'>
                        <h1 className='text-capitalize'>Architectural, Structural, MEP, Interior & Landscaping <br />designs at their best</h1>
                        <p>Engineering Consortium Consulting Engineers is an association of highly qualified and experienced industry experts, carefully selected from various disciplines of the engineering industry.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <img className="d-block w-100" src="banner/Architectural-banner.jpg" alt="Second slide" />
                        <Carousel.Caption className='text-start'>
                        <h1 className='text-capitalize'>Architectural, Structural, MEP, Interior & Landscaping <br />designs at their best</h1>
                        <p>Engineering Consortium Consulting Engineers is an association of highly qualified and experienced industry experts, carefully selected from various disciplines of the engineering industry.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                
                    <Carousel.Item>
                        <img className="d-block w-100" src="banner/Structural-banner.jpg" alt="Third slide" />
                        <Carousel.Caption className='text-start'>
                        <h1 className='text-capitalize'>Architectural, Structural, MEP, Interior & Landscaping <br />designs at their best</h1>
                        <p>Engineering Consortium Consulting Engineers is an association of highly qualified and experienced industry experts, carefully selected from various disciplines of the engineering industry.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <img className="d-block w-100" src="banner/Landscaping-designs.jpg" alt="Third slide" />
                        <Carousel.Caption className='text-start'>
                        <h1 className='text-capitalize'>Architectural, Structural, MEP, Interior & Landscaping <br />designs at their best</h1>
                        <p>Engineering Consortium Consulting Engineers is an association of highly qualified and experienced industry experts, carefully selected from various disciplines of the engineering industry.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <img className="d-block w-100" src="banner/Interior-banner.jpg" alt="Third slide" />
                        <Carousel.Caption className='text-start'>
                        <h1 className='text-capitalize'>Architectural, Structural, MEP, Interior & Landscaping <br />designs at their best</h1>
                        <p>Engineering Consortium Consulting Engineers is an association of highly qualified and experienced industry experts, carefully selected from various disciplines of the engineering industry.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
        </div>
  );
}

export default MainSlider;