import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';

function ServicesSlider() {
    var settings = {
        dots: true,
        infinite: false,
        autoScroll: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [{
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }]
    };
    return (
      <Slider {...settings}>
        <div>
            <LinkContainer to="/services/architecture-methodology/">
                <Nav.Link>
                    <div className="img-cont"><img src="/services/Architecture-methodology-Service.jpg" alt="" className="img-fluid" /></div>
                    <h4><span style={{display:"block"}}>01</span>Architecture Methodology</h4> 
                </Nav.Link>
            </LinkContainer>
        </div>
        <div>
            <LinkContainer to="/services/structural-and-civil-engineering/">
                <Nav.Link>
                    <div className="img-cont"><img src="/services/Structural.jpg" alt="" className="img-fluid" /></div>
                    <h4><span style={{display:"block"}}>02</span>Structural & Civil Engineering</h4> 
                </Nav.Link>
            </LinkContainer>
        </div>
        <div>
            <LinkContainer to="/services/mechanical-engineering/">
                <Nav.Link>
                    <div className="img-cont"><img src="/services/mechanical-engineering.jpg" alt="" className="img-fluid" /></div>
                    <h4><span style={{display:"block"}}>03</span>Mechanical Engineering</h4> 
                </Nav.Link>
            </LinkContainer>
        </div>
        <div>
            <LinkContainer to="/services/electrical-engineering/">
                <Nav.Link>
                    <div className="img-cont"><img src="/services/electrical-engineering.jpg" alt="" className="img-fluid" /></div>
                    <h4><span style={{display:"block"}}>04</span>Electrical Engineering</h4> 
                </Nav.Link>
            </LinkContainer>
        </div>
        <div>
            <LinkContainer to="/services/green-building-compliance/">
                <Nav.Link>
                    <div className="img-cont"><img src="/services/green-building-compliance-service.jpg" alt="" className="img-fluid" /></div>
                    <h4><span style={{display:"block"}}>05</span>Green Building Compliance</h4> 
                </Nav.Link>
            </LinkContainer>
        </div>
        <div>
            <LinkContainer to="/services/fire-and-security/">
                <Nav.Link>
                    <div className="img-cont"><img src="/services/fire-and-security-service.jpg" alt="" className="img-fluid" /></div>
                    <h4><span style={{display:"block"}}>06</span>Fire & Security</h4> 
                </Nav.Link>
            </LinkContainer>
        </div>
      </Slider>
      );
}
export default ServicesSlider;