import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Services from "./Services";
  import ArchitectureMethodology from "./Services/ArchitectureMethodology";
  import StructuralCivilEngineering from "./Services/StructuralCivilEngineering";
  import MechanicalEngineering from "./Services/MechanicalEngineering";
  import ElectricalEngineering from "./Services/ElectricalEngineering";
  import GreenBuildingCompliance from "./Services/GreenBuildingCompliance";
  import FireSecurity from "./Services/FireSecurity";
import Projects from "./Projects";
import Career from "./Career";
import ContactUs from "./ContactUs";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import './Script.js';
import ScrollToTop from "./Scroll";


function App() {
  return (
    <>
      <BrowserRouter>
          <ScrollToTop />
          <NavigationBar />
          <div id="Inerweb">
            <Routes>
              <Route path='/' element={<Home />}></Route>
              <Route path='/about-us' element={<AboutUs />}></Route>
              <Route path='/services' element={<Services />}></Route>
              <Route path='/projects' element={<Projects />}></Route>
              <Route path='/career' element={<Career />}></Route>
              <Route path='/contact-us' element={<ContactUs />}></Route>
              <Route path='/services/architecture-methodology' element={<ArchitectureMethodology />}></Route>
              <Route path='/services/structural-and-civil-engineering' element={<StructuralCivilEngineering />}></Route>
              <Route path='/services/mechanical-engineering' element={<MechanicalEngineering />}></Route>
              <Route path='/services/electrical-engineering' element={<ElectricalEngineering />}></Route>
              <Route path='/services/green-building-compliance' element={<GreenBuildingCompliance />}></Route>
              <Route path='/services/fire-and-security' element={<FireSecurity />}></Route>
            </Routes>
          </div>
          <Footer />
      </BrowserRouter>
    </>
  );
}
export default App;