import React from "react";
import { LinkContainer } from 'react-router-bootstrap';

function ArchitectureMethodology() {
    const ServicesTitle = { 
        "title":"Architecture Methodology", 
    };
    const ServicesList = [
        { listItem: "Feasibility studies and recommendations"},
        { listItem: "Preparation of preliminary designs, cost estimates and planning permission requirements on approval" },
        { listItem: "Preparation of detailed design and production drawings for building regulation approvals and construction" },
        { listItem: "Speciﬁcation and tender documents" },
        { listItem: "Preparation of schedules and supervision of the construction process to ensure quality implementation of the designs" },
      ];
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row flex-lg-row flex-sm-column-reverse flex-column-reverse">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <h1 className="mb-5">{ServicesTitle.title}</h1>
                        <ul>
                        {ServicesList.map((data, idx) => (
                            <li>{data.listItem}</li>
                        ))}
                        </ul>
                        <LinkContainer to="/services/">
                            <button type="button" className="btn btn-success back">Back to Services</button>
                        </LinkContainer>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <img src="/services/Architecture-methodology-Service.jpg" alt="Architecture Methodology Service" className="img-fluid mb-3" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default ArchitectureMethodology;