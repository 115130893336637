import React from "react";
import { LinkContainer } from 'react-router-bootstrap';

function StructuralCivilEngineering() {
    const ServicesDesc = { 
        "title":"Structural & Civil Engineering", 
        "p1":"ECCE provides complete structural and civil engineering support for the design, analysis, inspection and evaluation of structural systems.",
        "p2":"Our structural engineering studio is distinguished by its highly experienced and innovative engineers who work closely with our architecture and MEP departments to develop satisfactory solutions to suit all of our clients’ structural needs."
    };
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row flex-lg-row flex-sm-column-reverse flex-column-reverse">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <h1 className="mb-5">{ServicesDesc.title}</h1>
                        <p>{ServicesDesc.p1}</p>
                        <p>{ServicesDesc.p2}</p>
                        <LinkContainer to="/services/">
                            <button type="button" className="btn btn-success back">Back to Services</button>
                        </LinkContainer>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <img src="/services/Structural.jpg" alt="Structural" className="img-fluid mb-3" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default StructuralCivilEngineering;