import React from "react";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useEffect } from "react";

function Career() {
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    };
    useEffect (() => {
        document.title = "Engineering Consortium | Career";
    }, 
    []);
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5 text-center">Upload Your Resume</h1>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Frist Name is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Phone is required</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Profession</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">Profession is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Upload Resume / CV</Form.Label>
                                    <Form.Control type="file" required name="file" />
                                    <Form.Control.Feedback type="invalid">Resume is required</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Button type="submit">Send</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Career;