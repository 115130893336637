import React from "react";
import { LinkContainer } from 'react-router-bootstrap';

function ElectricalEngineering() {
    const ServicesDesc = { 
        "title": "Electrical Engineering",
        "p1": "Electrical services are a vital and costly component of modern buildings. At ECCE we undertake feasibility and site surveys, develop project briefs, produce detailed designs, issue tender invitations and undertake site supervision.",
        "p2": "We are the leading providers of electrical design services, which include:",
    };
    const ServicesList = [
        { listItem: "Onsite or standby generator" },
        { listItem: "Incoming electrical power substations" },
        { listItem: "Overhead and underground distribution systems" },
        { listItem: "Building power supply systems" },
        { listItem: "Building power distribution" },
        { listItem: "Building lighting and small power outlets" },
        { listItem: "Building data, communication and ﬁre alarm systems" },
        { listItem: "Fiber optic networking" },
        { listItem: "Specialty lighting" },
        { listItem: "Outdoor lighting & facade" },
      ];
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row flex-lg-row flex-sm-column-reverse flex-column-reverse">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <h1 className="mb-5">{ServicesDesc.title}</h1>
                        <p>{ServicesDesc.p1}</p>
                        <p>{ServicesDesc.p2}</p>
                        <ul>
                        {ServicesList.map((data, idx) => (
                            <li>{data.listItem}</li>
                        ))}
                        </ul>
                        <LinkContainer to="/services/">
                            <button type="button" className="btn btn-success back">Back to Services</button>
                        </LinkContainer>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <img src="/services/electrical-engineering.jpg" alt="Electrical Engineering.jpg" className="img-fluid mb-3" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default ElectricalEngineering;