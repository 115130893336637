import React from "react";
import { LinkContainer } from 'react-router-bootstrap';

function MechanicalEngineering() {
    const ServicesDesc = { 
        "title":"Mechanical Engineering",
        "p1":"ECCE aims to ensure that all mechanical requirements are planned and designed efﬁciently. Our HVAC, or ‘climate control’ designs, are particularly important in the design of medium to large residential or commercial towers, and in non- static clean environments where humidity and temperature must be closely regulated. We base our heating, ventilation and air conditioning designs on the basic principles of thermodynamics, ﬂuid mechanics and heat transfer, while further striving to implement the most innovative mechanical engineering solutions drawn from expertise from around the world.", 
    };
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row flex-lg-row flex-sm-column-reverse flex-column-reverse">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <h1 className="mb-5">{ServicesDesc.title}</h1>
                        <p>{ServicesDesc.p1}</p>
                        <LinkContainer to="/services/">
                            <button type="button" className="btn btn-success back">Back to Services</button>
                        </LinkContainer>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <img src="/services/mechanical-engineering.jpg" alt="Mechanical Engineering" className="img-fluid mb-3" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default MechanicalEngineering;