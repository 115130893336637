import React, { useEffect } from "react";

function AboutUs() {
    useEffect (() => {
        document.title = "Engineering Consortium | About Us";
    }, 
    []);
    return (
        <>
        <div className="aboutus pt-5 pb-5" style={{backgroundImage: "url(/about-consortium.jpg)", backgroundRepeat: "no-repeat", backgroundSize:"cover",}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">About Us</h1>
                        <p>Engineering Consortium Consulting Engineers (ECCE) is an association of highly qualiﬁed and experienced industry experts, carefully selected from various disciplines of the engineering industry. Since its inception in 1998, ECCE has evolved into one of the leading engineering consultancy ﬁrms in the Middle East, providing innovative designs and supervision solutions for all types of engineering and architectural projects.</p>
                        <p>ECCE has earned the reputation of being one of the region’s leading service providers, with a particular focus on:</p>
                        <ul>
                            <li>Architectural Designs</li>
                            <li>Civil and Structural Engineering</li>
                            <li>Electrical and Telecommunication Designs</li>
                            <li>Plumbing and Drainage Designs</li>
                            <li>Fire Prevention and Security Designs</li>
                            <li>Energy Efﬁcient HVAC Designs</li>
                        </ul>
                        <p>ECCE adopts environmentally responsible practices as a way of reducing the carbon footprint and any negative impact on the environment resulting from the construction process. Our goal is to live lightly on earth, protecting the planet’s resources for future generations.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="ourteam pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src="/our-team.jpg" className="mx-auto d-block mb-4 img-fluid" alt="Ec International Team" />
                        <h2 className="mb-3 text-center">Our Team</h2>
                        <p className="text-center">Our success is largely due to our team of highly experienced executive management, dedicated technical professionals and the support provided by our valuable operations team. Our projects team maintains a solid foundation of trust, transparency and mutual respect in all projects, by stablishing and maintaining positive relationships with our clients, contractors and suppliers. Our technical departments are equipped with the latest software technology and equipment, allowing them to produce unique, bespoke and varied designs. Our members of staff have been carefully selected for their dedication and commitment to client servicing, and for excellence in their respective area of specialization. We are extremely proud of the high rate of employee retention at ECCE, and we attribute this to our commitment to ethical employment practices.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="mission pt-5 pb-5" style={{backgroundImage: "url(/footer-bg.jpg)", backgroundRepeat: "no-repeat", backgroundSize:"cover",}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="mb-3 text-center text-white">Mission Statement</h2>
                        <p className="text-center text-white">ECCE is dedicated to offering its clients high quality, bespoke and cost-effective solutions of an international and highly professional caliber. ECCE is committed to excellence and to building long term relationships with our clients, by providing them with high quality client servicing, through a team of dedicated industry experts who work together to transform our clients’ visions into reality.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="quality pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="mb-3">Quality Procedures & Practices</h2>
                        <p className="pt-3 pb-3">Quality control and safety form two critical pillars of the working ethics at ECCE during the cost control, design and planning stages. Inspectors and quality assurance personnel are involved in each phase of the project being developed to ensure quality standards and clients’ expectations are met.</p>
                    </div>
                </div>
                <div className="row d-flex">
                    <div className="col-md-6">
                        <img src="/quality-check.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <div>
                            <h3>Our Quality Manamgement</h3>
                            <ul>
                                <li>Policies stating and ensuring that quality, safety, environmental and human resources management objectives will be met</li>
                                <li>Plans outlining how risks will be identiﬁed and addressed to achieve agreed targets</li>
                                <li>Operational processes, procedures and work instruction methodologies</li>
                                <li>Procedures for checking, taking corrective action, auditing and monitoring performance</li>
                            </ul>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default AboutUs;