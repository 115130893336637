import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

function Projects() {
    useEffect (() => {
        document.title = "Engineering Consortium | Projects";
    }, 
    []);
    return (
        <>
        <div className="services pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">Projects</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/projects">
                            <div className="img-cont"><img src="/projects/Hospitality.jpg" alt="Hospitality" className="img-fluid" /></div>
                            <h4>Hospitality</h4> 
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/projects">
                            <div className="img-cont"><img src="/projects/Commercial.jpg" alt="Commercial" className="img-fluid" /></div>
                            <h4>Commercial</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/projects">
                            <div className="img-cont"><img src="/projects/Residential.jpg" alt="Residential" className="img-fluid" /></div>
                            <h4>Residential</h4>
                        </NavLink>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/projects">
                            <div className="img-cont"><img src="/projects/Mixed-USE.jpg" alt="Mixed USE" className="img-fluid" /></div>
                            <h4>Mixed USE</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/projects">
                            <div className="img-cont"><img src="/projects/Retail.jpg" alt="Retail" className="img-fluid" /></div>
                            <h4>Retail</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/projects">
                            <div className="img-cont"><img src="/projects/Educational.jpg" alt="Educational" className="img-fluid" /></div>
                            <h4>Educational</h4>
                        </NavLink>
                    </div>
                    <div className="col-md-12 mb-2 text-center">
                        <NavLink to="/projects">
                            <span className="btn btn-success grey">View All Projects</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Projects;