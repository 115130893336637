import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Logos() {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        centerPadding: '40px',
        arrows: false,
        responsive: [{
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }]
    };
    return (
      <Slider {...settings}>
        <div>
          <img src="/clientlogos/Damac.jpg" alt="" className="img-fluid" />
        </div>
        <div>
        <img src="/clientlogos/wasl.jpg" alt="" className="img-fluid" />
        </div>
        <div>
        <img src="/clientlogos/azizi.jpg" alt="" className="img-fluid" />
        </div>
        <div>
        <img src="/clientlogos/dubai-properties.jpg" alt="" className="img-fluid" />
        </div>
        <div>
        <img src="/clientlogos/aiaci.jpg" alt="" className="img-fluid" />
        </div>
        <div>
        <img src="/clientlogos/select-group.jpg" alt="" className="img-fluid" />
        </div>
      </Slider>
      );
}
export default Logos;