import React from "react";
import { NavLink } from "react-router-dom";
import Logos from "./Logos";
import ServicesSlider from "./ServicesSlider";
import Projects from "./Projects";
import MainSlider from "./MainSlider";
import { useEffect } from "react";

function Home() {
    useEffect(() => {
        document.body.classList.add('home')
            return () => {
            document.body.classList.remove('home')
        }
    })
    useEffect (() => {
        document.title = "Engineering Consortium | Home";
    }, 
    []);
    return (
        <>
        <MainSlider />
        <div className="homeabout pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">About Us</h1>
                        <p>Engineering Consortium Consulting Engineers (ECCE) is an association of highly qualiﬁed and experienced industry experts, carefully selected from various disciplines of the engineering industry. Since its inception in 1998, ECCE has evolved into one of the leading engineering consultancy ﬁrms in the Middle East, providing innovative designs and supervision solutions for all types of engineering and architectural projects.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="mt-5 mb-5 text-center">Engineering Consortium<br /> Consulting Engineers</h2>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                        <img src="ABDULLA-MOHAMMED-RAFIA-ALDALLAL-Photo.jpg" alt="" className="img-fluid" />
                        <h4 className="mt-3 mb-3">ABDULLA MOHAMMED RAFIA ALDALLAL</h4>
                        <p>PARTNER<br /> BSC CIVIL ENGINEERING (USA)<br /> MSC CIVIL ENGINEERING (USA)</p>
                    </div>
                    <div className="col-md-4">
                        <img src="Dr.-MARCUS-G.-GEORGE-Photo.jpg" alt="" className="img-fluid" />
                        <h4 className="mt-3 mb-3">DR. MARCUS G.GEORGE</h4>
                        <p>CEO/PARTNER<br /> BSC CIVIL ENGINEERING (USA)<br /> MSC CIVIL ENGINEERING (USA)<br /> PHD CIVIL ENGINEERING (UK)</p>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-12 text-end mt-4 mb-4">
                        <NavLink to="/about-us"><span className="btn btn-success grey">Read More</span></NavLink>
                    </div>
                </div>
            </div>
        </div>
        <div className="services pt-5 pb-5" style={{backgroundColor:"#E8E8E8"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">Services</h1>
                    </div>
                    <div className="col-md-12">
                        <div className="services-slick"><ServicesSlider /></div>
                    </div>
                    <div className="col-md-12 mt-5 mb-2 text-center">
                        <NavLink to="/services"><span className="btn btn-success white">View All Services</span></NavLink>
                    </div> 
                </div>
            </div>
        </div>
        <Projects /> 
        <div className="clientlogos pt-5 pb-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5 text-center">Clients and Partners</h1>
                    </div>
                    <div className="col-md-12">
                        <div className="logos-"><Logos /></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Home;