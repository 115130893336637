import React from "react";
import { NavLink } from "react-router-dom";
import './Styles.css';

function NavigationBar() {
    return (
        <>
        <div className="top_bar">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-6">
                        <NavLink to="/" style={{opacity:"1"}}><img src="/ec-logo.png" className="logo" alt="Logo" /></NavLink>
                    </div>
                    <div className="col-md-8 col-6">
                        <nav className="navbar navbar-expand-lg">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="justify-content-end collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/" ><span>Home</span></NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/about-us" ><span>About Us</span></NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/services" ><span>Services</span></NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/projects" ><span>Projects</span></NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/career" ><span>Career</span></NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/contact-us" ><span>Contact Us</span></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default NavigationBar;