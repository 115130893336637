import React from "react";
import { LinkContainer } from 'react-router-bootstrap';

function GreenBuildingCompliance() {
    const ServicesDesc = { 
        "title":"Green Building Compliance", 
        "p1": "Our services enable decision-makers to optimize the relationship between infrastructure, buildings, nature and people to create vibrant and sustainable communities. We have the experience, human capital and tools to help our clients appreciate the integrated relationship between building systems and the surrounding environment, and the impact each speciﬁc decision has on overall building performance Promoting environmentally responsible building materials, methods and practices is part of our mission.",
        "p2": "We deliver qualitative, green building designs that create energy savings, a healthier environment for the occupants and an increased property value.",
    };
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row flex-lg-row flex-sm-column-reverse flex-column-reverse">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <h1 className="mb-5">{ServicesDesc.title}</h1>
                        <p>{ServicesDesc.p1}</p>
                        <p>{ServicesDesc.p2}</p>
                        <LinkContainer to="/services/">
                            <button type="button" className="btn btn-success back">Back to Services</button>
                        </LinkContainer>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <img src="/services/green-building-compliance-service.jpg" alt="Green Building Compliance" className="img-fluid mb-3" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default GreenBuildingCompliance;