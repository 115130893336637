import React from "react";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useEffect } from "react";

function ContactUs() {
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    };
    useEffect (() => {
        document.title = "Engineering Consortium | Contact Us";
    }, 
    []);
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="mb-5">Contact Details</h1>
                        <p>Phone: <a href="tel:+9714283 0770">+971 4 283 0770</a><br />
                        Fax: +971 4 283 0771<br />
                        Email: <a href="mailto:info@ecinternational.com">info@ecinternational.com</a></p>
                        <br />
                        <p>
                        Office No. 901 <br />
                        9th Floor <br />
                        Onyx Tower 1 <br />
                        Sheikh Zayed Rd. <br />
                        The Greens, Dubai – UAE
                        </p>
                    </div>
                    <div className="col-md-6">
                        <h1 className="mb-5">Contact Us</h1>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">please enter your name</Form.Control.Feedback>
                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" placeholder="" />
                                    <Form.Control.Feedback type="invalid">please enter your email</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control required type="text" placeholder="" />
                                    <Form.Control.Feedback type="invalid">please enter your phone</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control type="text" placeholder="" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-4">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="" />
                                </Form.Group>
                            </Row>
                            <Button type="submit">Send</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default ContactUs;