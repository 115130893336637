import React from "react";
import { LinkContainer } from 'react-router-bootstrap';

function FireSecurity() {
    const ServicesDesc = { 
        "title":"Fire & Security", 
        "p1": "ECCE produces the most up to date designs, which incorporate ﬁre and security technology that complies with the most stringent safety standards. Our expertise has led us to create state of the art security and ﬁre alarm system designs; including automatic and manual initiating devices, audible and visual indicating appliances, radio transmitters, automatic dialers, and cellular backup dialers.",
        "p2": "Some of our designs include multi-criteria and multi-technology smoke detectors with adjustable sensitivity ratings, eat detectors, linear beam detectors and high sensitivity smoke detection systems for the early detection of ﬁres.",
    };
    return (
        <>
        <div className="contactus pt-5 pb-5">
            <div className="container">
                <div className="row flex-lg-row flex-sm-column-reverse flex-column-reverse">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <h1 className="mb-5">{ServicesDesc.title}</h1>
                        <p>{ServicesDesc.p1}</p>
                        <p>{ServicesDesc.p2}</p>
                        <LinkContainer to="/services/">
                            <button type="button" className="btn btn-success back">Back to Services</button>
                        </LinkContainer>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <img src="/services/fire-and-security-service.jpg" alt="Fire and Security" className="img-fluid mb-3" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default FireSecurity;