import React from "react";
import {NavLink } from "react-router-dom";

function Footer() {
    return (
        <>
        <div className="footer" style={{backgroundImage: "url(/footer-bg.jpg)", backgroundRepeat: "no-repeat", backgroundSize:"cover",}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <NavLink to="/"><img src="/ec-logo.png" className="footer-logo" alt="Logo" /></NavLink>
                    </div> 
                </div> 
                <div className="row">   
                    <div className="col-md-3 ft-enter">
                        <h2 className="mb-5">Menu</h2>
                        <div className="fmenu">
                        <li className="nav-item">
                                <NavLink className="nav-link" to="/" ><span>Home</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about-us" ><span>About Us</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/services" ><span>Services</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/projects" ><span>Projects</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/career" ><span>Career</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/contact-us" ><span>Contact Us</span></NavLink>
                            </li>
                        </div>
                    </div>
                    <div className="col-md-3 ft-enter">
                        <h2 className="mb-5">Contact Details</h2>
                        <p>Office No. 901<br />
                        9th Floor<br />
                        Onyx Tower 1<br />
                        Sheikh Zayed Rd.<br />
                        The Greens, Dubai - UAE</p>
                    </div>
                    <div className="col-md-6 ft-enter">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28905.108848345928!2d55.168584!3d25.097169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d1851d687ab%3A0xbc935cf19f37cbb7!2sENGINEERING%20CONSORTIUM%20-%20CONSULTING%20ENGINEERS!5e0!3m2!1sen!2sae!4v1678121356242!5m2!1sen!2sae" style={{width:"100%", height:"350px",}} title="ENGINEERING CONSORTIUM - CONSULTING ENGINEERS"></iframe>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    <p className="text-center m-0">Engineering Consortium Consulting Engineers 2023 | All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default Footer;